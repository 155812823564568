import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

Vue.config.productionTip = false;

import axios from "axios";
window.axios = axios.create({
	headers: { Authorization: `bearer ${store.getters.getToken}` },
});

import VueMeta from "vue-meta";
Vue.use(VueMeta);

import PortalVue from "portal-vue";
Vue.use(PortalVue);

import VueViewer from 'v-viewer'
Vue.use(VueViewer);

import vueKanban from 'vue-kanban'
Vue.use(vueKanban)

// import VueSocketIO from "vue-socket.io";
// import SocketIO from "socket.io-client";
// const logged = store.getters.isLoggedIn;

// if (logged) {
// 	Vue.use(
// 		new VueSocketIO({
// 			debug: process.env.NODE_ENV == 'development',
// 			connection: SocketIO(process.env.VUE_APP_API_URL, {
// 				widthCredentials: true,
// 				transports: ["websocket"],
// 				query: `token=${ store.getters.getToken }`,
// 			}),
// 			vuex: {
// 				store,
// 				actionPrefix: "SOCKET_",
// 				mutationPrefix: "SOCKET_",
// 			},
// 			options: { widthCredentials: true }, //Optional options
// 		})
// 	);
// }

new Vue({
	router,
	store,
	vuetify,
	render: (h) => h(App),
	methods: {
		acceso : (value) => {
			// console.log({value, permiso:  store.getters.getPermisos[value], resultado : !!(store.getters.getPermisos[value] & Number(store.getters.getTokenPayload.permisos)) })
			if ( store.getters.getPermisos == null || store.getters.getTokenPayload?.permisos == null )
				return false
			return !!(store.getters.getPermisos[value] & Number(store.getters.getTokenPayload?.permisos))}
	}
}).$mount("#app");
