import axios from "axios";

function urlB64ToUint8Array(base64String) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");
  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i)
    outputArray[i] = rawData.charCodeAt(i);

  return outputArray;
}

function decode64(data) {
  return new TextDecoder().decode(urlB64ToUint8Array(data));
}

const auth = {
  state: () => ({
    token: localStorage.getItem("token") || null,
    permisos: JSON.parse(localStorage.getItem("permisos")) || {},
    refreshLock: Date.now(),
    nombreEmpresa: null,
    coloresEmpresas: null,
    estados: JSON.parse(localStorage.getItem("estados")) || null,
  }),
  getters: {
    isLoggedIn: (state) => !!state.token,
    getToken: (state) => state.token,
    getTokenPayload: (state) =>
      state.token ? JSON.parse(decode64(state.token.split(".")[1])) : null,
    getNombreEmpresa: (state) => state.nombreEmpresa || "",
    getColoresEmpresas: (state) => state.coloresEmpresas || "",
    getPermisos: (state) => state.permisos,
    getEstados: (state) => state.estados,
  },
  mutations: {
    logout(state) {
      return new Promise((resolve) => {
        localStorage.removeItem("token");
        localStorage.removeItem("permisos");
        state.token = null;
        resolve();
      });
    },
    login(state, token) {
      localStorage.setItem("token", token);
      state.token = token;
    },
    setEstados(state, estados) {
      return new Promise((resolve) => {
        localStorage.setItem("estados", JSON.stringify(estados));
        state.estados = estados;
        resolve();
      });
    },
    setNombreEmpresa(state, domain) {
      return new Promise((resolve) => {
        state.nombreEmpresa = domain;
        resolve();
      });
    },
    setColoresEmpresas(state, obj) {
      return new Promise(async (resolve) => {
        state.coloresEmpresas = obj;
        resolve();
      });
    },
    setPermisos(state, per) {
      return new Promise(async (resolve) => {
        localStorage.setItem("permisos", JSON.stringify(per));
        state.permisos = per;
        resolve();
      });
    },
  },
  actions: {
    login({ commit }, { user, password }) {
      return new Promise(async (resolve, reject) => {
        try {
          const formData = new FormData();
          formData.append("username", user);
          formData.append("password", password);
          const { data: token } = await axios({
            method: "POST",
            url: `${process.env.VUE_APP_API_URL}/auth/login`,
            data: formData,
          });

          const { data: permisos } = await axios({
            method: "GET",
            url: `${process.env.VUE_APP_API_URL}/auth/permisos`,
            headers: {
              Authorization: `${token.token_type} ${token.access_token}`,
            },
          });

          const { data: estados } = await axios({
            method: "GET",
            url: `${process.env.VUE_APP_API_URL}/estados_front`,
            headers: {
              Authorization: `${token.token_type} ${token.access_token}`,
            },
          });

          axios = axios.create({
            headers: {
              Authorization: `${token.token_type} ${token.access_token}`,
            },
          });

          await commit("login", token.access_token);
          await commit("setPermisos", permisos);
          await commit("setEstados", estados);
          resolve();
        } catch (e) {
          console.error(e);
          reject();
        }
      });
    },
    refreshToken({ commit, getters, state }) {
      return new Promise(async (resolve, reject) => {
        if (Date.now() < state.refreshLock || !getters.getToken) {
          resolve();
          return;
        }
        let token;
        try {
          const { data } = await axios({
            method: "GET",
            url: `${process.env.VUE_APP_API_URL}/auth/refresh_token`,
            headers: { Authorization: `bearer ${getters.getToken}` },
          });
          token = data;
        } catch {
          reject();
          return
        }

        const { data: permisos } = await axios({
          method: "GET",
          url: `${process.env.VUE_APP_API_URL}/auth/permisos`,
          headers: {
            Authorization: `${token.token_type} ${token.access_token}`,
          },
        });

        const { data: estados } = await axios({
          method: "GET",
          url: `${process.env.VUE_APP_API_URL}/estados_front`,
          headers: {
            Authorization: `${token.token_type} ${token.access_token}`,
          },
        });

        state.refreshLock = Date.now() + 1000 * 60 * 10;

        resolve(
          await Promise.all([
            commit("login", token.access_token),
            commit("setEstados", estados),
            commit("setPermisos", permisos),
          ])
        );
      });
    },
    logout({ commit }) {
      return new Promise(async (resolve) => {
        commit("logout");
        resolve();
      });
    },
    setNombreEmpresa({ commit }, domain) {
      return new Promise(async (resolve) => {
        await commit("setNombreEmpresa", domain);
        resolve();
      });
    },
    setColoresEmpresas({ commit }, obj) {
      return new Promise(async (resolve) => {
        await commit("setColoresEmpresas", obj);
        resolve();
      });
    },
    setPermisos({ commit }, permisos) {
      return new Promise(async (resolve) => {
        await commit("setPermisos", permisos);
        resolve();
      });
    },
  },
};

export default auth;
