const routes = {
  path: "/comisiones",
  component: () => import("./Module.vue"),
  meta: { requiresAuth: true, requiresAdmin: true, acceso: "EDITAR_ESTUDIOS" },
  children: [
    {
      path: "",
      name: "Comisiones",
      component: () => import("./views/Comisiones.vue"),
    },
  ],
};

export default routes;
