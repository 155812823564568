const routes = {
  path: "/controles",
  name: 'Controles',
  component: () => import("./Module.vue"),
  meta: { requiresAuth: true, acceso: "EDITAR_ESTUDIOS" },
  redirect: '/controles/estados_estudios',
  children: [
    {
      path: "estados_estudios",
      name: "ControlesEstadosEstudios",
      component: () => import("./views/AutoCrud.vue"),
      props: (route) => ({ model : 'Estados', router_prefix: 'estados' }),
    },
    {
      path: "tipos_contacto",
      name: "ControlesTipoContacto",
      component: () => import("./views/AutoCrud.vue"),
      props: (route) => ({ model : 'TipoContacto', router_prefix: 'tipos_contacto' }),
    },
    {
      path: "instaladoras",
      name: "ControlesInstaladoras",
      component: () => import("./views/AutoCrud.vue"),
      props: (route) => ({ model : 'Instaladora', router_prefix: 'instaladoras' }),
    },
    {
      path: "empresas",
      name: "ControlesEmpresas",
      component: () => import("./views/AutoCrud.vue"),
      props: (route) => ({ model : 'Empresa', router_prefix: 'empresa' }),
    },
    {
      path: "formatos",
      name: "ControlesFormatos",
      component: () => import("./views/AutoCrud.vue"),
      props: (route) => ({ model : 'Formato', router_prefix: 'formato' }),
    },
    {
      path: "niveles_interes",
      name: "ControlesNivelesInteres",
      component: () => import("./views/AutoCrud.vue"),
      props: (route) => ({ model : 'NivelInteres', router_prefix: 'nivel_interes' }),
    },
    {
      path: "tipo_evento",
      name: "ControlesTipoEvento",
      component: () => import("./views/AutoCrud.vue"),
      props: (route) => ({ model : 'TipoEvento', router_prefix: 'tipo_evento' }),
    },
    {
      path: "inversores",
      name: "ControlesInversores",
      component: () => import("./views/AutoCrud.vue"),
      props: (route) => ({ model : 'Inversor', router_prefix: 'inversores' }),
    },
    {
      path: "paneles",
      name: "ControlesPaneles",
      component: () => import("./views/AutoCrud.vue"),
      props: (route) => ({ model : 'Panel', router_prefix: 'paneles' }),
    },
    {
      path: "tipo_cliente",
      name: "ConstrolesTipoCliente",
      component: () => import("./views/AutoCrud.vue"),
      props: (route) => ({ model : 'TipoCliente', router_prefix: 'tipo_cliente' }),
    },
    // {
    //   path: "estados",
    //   name: "ControlesEstados",
    //   component: () => import("./views/Estados.vue"),
    // },
    // {
    //   path: "tipos_contacto",
    //   name: "ControlesTiposContacto",
    //   component: () => import("./views/Contactos.vue"),
    // },
    // {
    //   path: "instaladoras",
    //   name: "ControlesInstaladoras",
    //   component: () => import("./views/Instaladoras.vue"),
    // },
  ],
};

export default routes;
